:root {
  --yellow: #e0c416;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --dark-grey: #2b2b2b;
  --near-black: #0f0f0f;
  --black: #000000;
  --dark-blue: #021e42;
  --bright-blue: #01dde9;
  --light-blue: #bfe5e7;
}
